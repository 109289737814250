
import {useState, useEffect} from 'react';
import Axios from 'axios';
import Loading from '../Components/Loader';
import ErrorMessage from '../Components/ErrorMessage';
import {useHistory} from 'react-router-dom'
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {apiURL} from '../APIs/config';

export default function LoginPage(){

    const [email, setEmail]= useState('');
    const [password, setPassword]= useState('');
    const [loading, setLoading]= useState(false);
    const [errors, setError]= useState(false);

    const history = useHistory();

    function Copyright() {
        return (
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://main--agitated-mahavira-b37e00.netlify.app/">
              Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        );
      }
      
      const useStyles = makeStyles((theme) => ({
        paper: {
          marginTop: theme.spacing(8),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
        avatar: {
          margin: theme.spacing(1),
          backgroundColor: theme.palette.secondary.main,
        },
        form: {
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(1),
        },
        submit: {
          margin: theme.spacing(3, 0, 2),
        },
      }));
    

    useEffect(()=>{
        const userInfo = localStorage.getItem("userInfo")
        if(userInfo){
            history.push('/')
        }
    },[history])

    const submitHandler = async(e) =>{
        e.preventDefault(); 

        setLoading(true);

        try {
            const config = {
                headers:{
                    "Content-type":"application/json"
                }
            }
            
            const {data} = await Axios.post(  `${apiURL}/login`,
            {
                email,
                password,
            },
            config
            );
            if(data === "Invalid Password")
            {
              alert('Wrong Password or Username')
            }
            else{
            console.log("Data"+data);
            localStorage.setItem('userInfo', JSON.stringify(data));
            setLoading(false);
            alert(`Hey ${data.name}! You are logged in`);
            history.push('/');
          }
        }
        catch (error){
            setError(error.response.data.message)
            console.log(errors);
        }

    }
    const classes = useStyles();

    return(
        <>

<Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src ={`${apiURL}/image/logo.png`} style={{width:100, height:100}}/>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e)=>setEmail(e.target.value)} value={email}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e)=>setPassword(e.target.value)} value={password}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={submitHandler}
          >
            Sign In
          </Button> 
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
        </>
    );
}